export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL'; 
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const ACTIVATE_FAIL = 'ACTIVATE_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOAD_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOAD_USER_FAIL = 'LOGIN_USER_FAIL';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAIL = 'AUTHENTICATE_FAIL';
export const LOGOUT = 'LOGOUT'; 
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'; 
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'; 
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL'; 
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'; 
export const LOAD_PROFILE_FAIL = 'LOAD_PROFILE_FAIL';
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const SET_RESET_SUCCESS = 'SET_RESET_SUCCESS';
export const SET_ACTIVATE_SUCCESS = 'SET_ACTIVATE_SUCCESS';
export const SET_SIGNUP_SUCCESS = 'SET_SIGNUP_SUCCESS';
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_FAIL = 'LOAD_USER_DATA_FAIL';


export const NEW_CLIENT_SUCCESS = 'NEW_CLIENT_SUCCESS';
export const NEW_CLIENT_FAIL = 'NEW_CLIENT_FAIL';
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const LOAD_CLIENTS_FAIL = 'LOAD_CLIENTS_FAIL';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL'
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS'
export const CLIENT_FOUND_SOMEWHERE = 'CLIENT_FOUND_SOMEWHERE'
export const CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND'


export const LOAD_DEALS_SUCCESS = 'LOAD_DEALS_SUCCESS';
export const LOAD_DEALS_FAIL = 'LOAD_DEALS_FAIL';
export const NEW_DEAL_SUCCESS = 'NEW_DEAL_SUCCESS';
export const NEW_DEAL_FAIL = 'NEW_DEAL_FAIL';
export const DELETE_DEAL_FAIL = 'DELETE_DEAL_FAIL';
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL'
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS'


export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';


export const NEW_CARD_FAIL = 'NEW_CARD_FAIL'
export const NEW_CARD_SUCCESS = 'NEW_CARD_SUCCESS'
export const RESET_CARD_FORM = 'RESET_CARD_FORM'

export const LOAD_PROPERTIES_SUCCESS = 'LOAD_COMMS_SUCCESS'
export const LOAD_PROPERTIES_FAIL = 'LOAD_COMMS_FAIL'


export const PROFILE_WIDGET_CLOSE = 'PROFILE_WIDGET_CLOSE'
export const PROFILE_WIDGET_OPEN = 'PROFILE_WIDGET_OPEN'

export const LOAD_CARDS_SUCCESS = 'LOAD_CARDS_SUCCESS'
export const LOAD_CARDS_FAIL = 'LOAD_CARDS_FAIL'


export const NEW_TASK_FAIL = 'NEW_TASK_FAIL'
export const NEW_TASK_SUCCESS = 'NEW_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const LOAD_TASKS_FAIL = 'LOAD_TASKS_FAIL'
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS'

export const NEW_LIST_SUCCESS = 'NEW_LIST_SUCCESS';
export const NEW_LIST_FAIL = 'NEW_LIST_FAIL';
export const LOAD_LISTS_SUCCESS = 'LOAD_LISTS_SUCCESS';
export const LOAD_LISTS_FAIL = 'LOAD_LISTS_FAIL';
export const SET_LIST_FOR_EDIT = 'SET_LIST_FOR_EDIT';
export const DELETE_LIST_FAIL = 'DELETE_LIST_FAIL';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const UPDATE_OPTION_SUCCESS = 'UPDATE_OPTION_SUCCESS';
export const UPDATE_OPTION_FAIL = 'UPDATE_OPTION_FAIL';
export const NEW_OPTION_SUCCESS = 'NEW_OPTION_SUCCESS';
export const NEW_OPTION_FAIL = 'NEW_OPTION_FAIL';
export const DELETE_OPTION_SUCCESS = 'DELETE_OPTION_SUCCESS';
export const DELETE_OPTION_FAIL = 'DELETE_OPTION_FAIL';
export const LOAD_OPTIONS_SUCCESS = 'LOAD_OPTIONS_SUCCESS';
export const LOAD_OPTIONS_FAIL = 'LOAD_OPTIONS_FAIL';
export const SEARCH_CLIENT_FAIL = 'SEARCH_CLIENT_FAIL';
export const SEARCH_CLIENT_SUCCESS = 'SEARCH_CLIENT_SUCCESS';
export const SEARCH_PROPERTY_FAIL = 'SEARCH_PROPERTY_FAIL';
export const SEARCH_PROPERTY_SUCCESS = 'SEARCH_PROPERTY_SUCCESS';
export const CLEAR_OPTIONS_FAIL = 'CLEAR_OPTIONS_FAIL';
export const CLEAR_OPTIONS_SUCCESS = 'CLEAR_OPTIONS_SUCCESS';
export const SET_SEARCH_CLIENT_SUCCESS = 'SET_SEARCH_CLIENT_SUCCESS';
export const SET_SEARCH_PROP_FAIL = 'SET_SEARCH_PROP_FAIL';
export const SET_SEARCH_PROP_SUCCESS = 'SET_SEARCH_PROP_SUCCESS';
export const SET_SEARCH_CLIENT_FAIL = 'SET_SEARCH_CLIENT_FAIL';
export const RETRIEVE_LIST_FAIL = 'RETRIEVE_LIST_FAIL';
export const RETRIEVE_LIST_SUCCESS = 'RETRIEVE_LIST_SUCCESS';
export const RESET_CLIENT = 'RESET_CLIENT';
export const RESET_PROP = 'RESET_PROP';
export const RESET_CLIENT_RESULTS = 'RESET_CLIENT_RESULTS';
export const RESET_PROPERTY_RESULTS = 'RESET_PROPERTY_RESULTS';
export const UPDATE_OPTION_ORDER_SUCCESS = 'UPDATE_OPTION_ORDER_SUCCESS';
export const UPDATE_OPTION_ORDER_FAIL = 'UPDATE_OPTION_ORDER_FAIL';
export const UPDATE_LIST_OPTIONS_SUCCESS = 'UPDATE_LIST_OPTIONS_SUCCESS';
export const UPDATE_LIST_OPTIONS_FAIL = 'UPDATE_LIST_OPTIONS_FAIL';
export const LOAD_LIST_SUCCESS = 'LOAD_LIST_SUCCESS';
export const LOAD_LIST_FAIL = 'LOAD_LIST_FAIL';
export const SET_OPTION_ORDER = 'SET_OPTION_ORDER';
export const LOAD_LISTS_EQUAL = 'LOAD_LISTS_EQUAL';
export const USER_DATA_UNCHANGED = 'USER_DATA_UNCHANGED';


export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_CLIENT_VIEW = 'SET_CLIENT_VIEW';
export const RESET_CLIENT_VIEW = 'RESET_CLIENT_VIEW';
export const RESET_SEND_MODE = 'RESET_SEND_MODE';
export const SET_SEND_MODE = 'SET_SEND_MODE';
export const SET_LIST_MODE = 'SET_LIST_MODE';
export const RESET_LIST_MODE = 'RESET_LIST_MODE';
export const UPDATE_OPTIONS_ORDER = 'UPDATE_OPTIONS_ORDER';
export const RESET_DEAL_FORM = 'RESET_DEAL_FORM';
export const RESET_REORDER_MODE = 'RESET_REORDER_MODE';
export const SET_REORDER_MODE = 'SET_REORDER_MODE';
export const TAB_SWITCH_CLEAR = 'TAB_SWITCH_CLEAR';
export const RESET_COMMISSION_SEARCH = 'RESET_COMMISSION_SEARCH';
export const RESET_DEAL_MODE = 'RESET_DEAL_MODE';
export const SET_DEAL_MODE = 'SET_DEAL_MODE';


export const LOAD_DEAL_FAIL = 'LOAD_DEAL_FAIL';
export const LOAD_DEAL_SUCCESS = 'LOAD_DEAL_SUCCESS';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAIL = 'UPDATE_DEAL_FAIL';
export const RESET_EDIT_LIST = 'RESET_EDIT_LIST';
export const LOCK_OUT = 'LOCK_OUT';
